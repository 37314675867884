import * as queryString from 'query-string';
import { KeyboardEventHandler } from 'react';
import cookie from 'react-cookies';

import { OriginArea } from '@videoblocks/events-ts/lib/storyblocks/search/OriginAreaEnum';
import { Telemetry } from '@videoblocks/kafka-rest-client';

import { Folder } from '../Member/Folders/types';
import { StockItemArtistInterface } from '../app/Search/containers/MenuContainerInterfaces';
import { urlSegmentForContentClass } from '../app/Shared/utils/utils';
import { contentTypes, userFriendlySearchTypes } from './Constants';
import {
  BUSINESS_LICENSE_NAME,
  ENTERPRISE_LICENSE_NAME,
  INDIVIDUAL_LICENSE_NAME,
  SiteEnum,
} from './SiteConstants/SiteConstants';
import { StockItem, StockItemContext } from './types/StockItemTypes';

declare const window: Window & {
  recommendedMusicDrawerTimer: () => Promise<void>;
};

export const COOKIE_TRUE = 't';
export const COOKIE_FALSE = 'f';

export const VIDEO = 'video';
export const AUDIO = 'audio';
export const IMAGE = 'image';

/**
 * Wrapper around console.warn that keeps our test output clean.
 * @param args
 */
function warn(...args) {
  if (process.env.NODE_ENV !== 'test') {
    console.warn(...args);
  }
}

/**
 * Builds request URL
 *
 * @param baseUrl
 * @param queryParameters
 * @param stringifyOptions overrides default queryString.stringify() options at top level
 * @returns {*}
 */
export function buildRequestUrl(
  baseUrl,
  queryParameters,
  stringifyOptions = {}
) {
  // Handle case when baseUrl contains query parameters
  const baseParamString = queryString.extract(baseUrl);
  if (baseParamString) {
    //We no longer call queryString.parse because we want to maintain the order of our params for SEO purposes
    baseUrl = baseUrl.split('?')[0];
  }
  const combinedOptions: queryString.StringifyOptions = {
    arrayFormat: 'index',
    sort: false,
    ...stringifyOptions,
  };

  //TODO ONE-1476 Update this to maintain the order of queryParameters instead of sorting them in stringify
  const query = queryString.stringify(queryParameters, combinedOptions);
  const returnQuery = [baseParamString, query].filter(Boolean).join('&');
  return returnQuery.length > 0 ? baseUrl + '?' + returnQuery : baseUrl;
}

export function getPageUrl(page, itemId?: any, windowLocation?: Location) {
  // This allows us to manually mock window.location for testing
  windowLocation = windowLocation || window.location;

  const searchParams = windowLocation
    ? windowLocation.search
    : __CURRENT_SEARCH_PARAMS__;
  //We turned off sorting to help with SEO efforts to make sure canonical links match
  const parameters = queryString.parse(searchParams, { sort: false });

  parameters.page = page;

  if (itemId) {
    parameters.item_id = itemId;
  }

  if (parameters.mrkt_page) {
    parameters.mrkt_page = page;
  }

  if (parameters.combined_page) {
    parameters.combined_page = page;
  }

  let path = windowLocation ? windowLocation.pathname : __CURRENT_PATH__;
  path = path.split('?')[0]; // Ensure that no query string is present (__CURRENT_PATH__ may contain a query string)

  return buildRequestUrl(path, parameters);
}

/**
 * Removes all search parameters unrelated to SEO.
 * @param {string} url url
 * @return {string} filtered url
 */
export function filterUrlSeoSearchParameters(url) {
  return queryString.pick(url, ['categories', 'media-type', 'page'], {
    sort: false,
  });
}

/**
 * If we are here its safe to assume we have
 * access to browser side data.
 */
function _getCurrentSiteFromBrowser() {
  const body = document.getElementsByTagName('body')[0];

  if (body.classList.contains('site-videoblocks')) {
    return SiteEnum.Videoblocks;
  } else if (body.classList.contains('site-graphicstock')) {
    return SiteEnum.Graphicstock;
  } else if (body.classList.contains('site-audioblocks')) {
    return SiteEnum.Audioblocks;
  } else {
    warn(
      'Unknown __CURRENT_SITE__ value and could not get it from the body class either, using host strategy.'
    );
    return null;
  }
}

export function getCurrentSite(): SiteEnum {
  // To allow for unit test overwrite
  const root = typeof window === 'undefined' ? global : window;

  const currentSite = (root as any).__CURRENT_SITE__?.toLowerCase();

  const validSites = [
    SiteEnum.Videoblocks,
    SiteEnum.Graphicstock,
    SiteEnum.Audioblocks,
    SiteEnum.Storyblocks,
  ];

  return validSites.includes(currentSite)
    ? currentSite
    : _getCurrentSiteFromBrowser();
}

export const isOnProductAgnosticPage = () =>
  getCurrentSite() === SiteEnum.Storyblocks;

export const isOnCurrentSite = (site: SiteEnum) => {
  const root = typeof window === 'undefined' ? global : window;
  const currentPath = ((root as any).__CURRENT_PATH__ || '').toLowerCase();

  const isStoryBlocksHome = currentPath === '/';
  const isMakerLandingPage = currentPath === '/maker';

  if (isStoryBlocksHome || isMakerLandingPage) return false;

  return getCurrentSite() === site;
};

export function inSearchSite() {
  const root = typeof window === 'undefined' ? global : window;
  const currentParams = ((root as any).__CURRENT_PATH__ || '').toLowerCase();

  return currentParams.split('/').includes('search');
}

export function isCrossSite(site) {
  return site !== getCurrentSite();
}

export function getStockLabelForSite() {
  //returns clips, images, etc for each site.
  const currentSite = getCurrentSite();

  if ([SiteEnum.Graphicstock, SiteEnum.Storyblocks].includes(currentSite)) {
    return 'images';
  } else {
    return 'clips';
  }
}

export function getStockDownloadPrefix() {
  const site = getCurrentSite();
  switch (site) {
    case SiteEnum.Videoblocks:
      return 'video';
    case SiteEnum.Graphicstock:
    case SiteEnum.Storyblocks:
      return 'images';
    case SiteEnum.Audioblocks:
      return 'audio';
    default:
      throw new Error('Cannot get content type for site: ' + site);
  }
}

/**
 * Capitalizes the first letter of a string
 */
export function ucfirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/*
 * Ensures the url doesn't have more than one "?"
 */
export function ensureProperlyFormattedQueryParams(url: string): string {
  if (!url) {
    return url;
  }

  const numberQuestionMarks = (url.match(/\?/g) || []).length;
  if (numberQuestionMarks > 1) {
    //Replace all question marks with ampersand
    let verifiedUrl = url.replace(/\?/g, '&');
    //Replace first ampersand with question mark
    verifiedUrl = verifiedUrl.replace('&', '?');
    return verifiedUrl;
  }

  return url;
}

export function getPreviewUrl(stockItemId, contentClass) {
  const prefix =
    urlSegmentForContentClass(contentClass) ?? getStockDownloadPrefix();
  return `/${prefix}/${stockItemId}/download/preview`;
}

export function getSliderValue(event) {
  const position = event.currentTarget.getBoundingClientRect().left;
  return (event.clientX - position) / event.currentTarget.clientWidth;
}

export function isStockItemInAnyFolder(
  bins: Folder[] = [],
  stockItem: StockItem,
  fn?: (bin: Folder, stockItem: StockItem) => boolean
): boolean {
  const callback =
    fn || ((bin) => bin.contentIds.includes(stockItem.contentId));

  return bins?.some((bin) => callback(bin, stockItem)) || false;
}

export function getSiteSpecificDefaultImage() {
  return '/assets/common/images/icons/member-bins/empty.svg';
}

// todo: unclear if this is used anywhere--could use _.debounce instead
export function debounce(fn, waitMs, context) {
  let timeout;
  return (...args) => {
    const later = () => {
      timeout = null;
      fn.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = window.setTimeout(later, waitMs);
  };
}

export function formatDurationSearch(minDuration, maxDuration) {
  const ignoreMin = minDuration === '' || parseInt(minDuration) === 0;
  const ignoreMax = maxDuration === '' || maxDuration === 10000;

  if (ignoreMax && ignoreMin) {
    return '';
  } else {
    let formattedDuration;
    if (!ignoreMax && !ignoreMin) {
      formattedDuration =
        ' between ' +
        parseInt(minDuration, 10) +
        ' seconds and ' +
        parseInt(maxDuration, 10);
    } else if (!ignoreMax) {
      formattedDuration = ' under ' + parseInt(maxDuration, 10);
    } else if (!ignoreMin) {
      formattedDuration = ' over ' + parseInt(minDuration);
    }
    return formattedDuration + ' seconds';
  }
}

export function prettifyList(intro, list) {
  let prettyList = '';
  if (list.length > 0) {
    prettyList += intro;
    const items =
      list.length === 1
        ? list[0]
        : [
            list.slice(0, list.length - 1).join(', '),
            list[list.length - 1],
          ].join(' and ');
    prettyList += items;
  }
  return prettyList;
}

export function setCookie(cookieName, cookieValue) {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365);
  cookie.save(cookieName, cookieValue, {
    expires,
    path: '/',
  });
}

export function roundUpToClosestMinute(secs) {
  return Math.ceil(secs / 60);
}

export function convertSecondsToMMSS(
  sec: string | number,
  maxNumberOfSeconds?: number
) {
  // @ts-expect-error secNum should be integer--parseInt doesn't want "number" types, but does work
  const secNum = parseInt(sec, 10);
  const minutes = Math.floor(secNum / 60);
  let seconds: number | string = secNum - minutes * 60;

  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  if (maxNumberOfSeconds) {
    const maxNumberOfMinutes = roundUpToClosestMinute(maxNumberOfSeconds);

    if (minutes >= maxNumberOfMinutes) {
      return `${maxNumberOfMinutes}:00+`;
    }
  }

  return `${minutes}:${seconds}`;
}

export function nextClosestMinuteInSeconds(lengthInSeconds) {
  return roundUpToClosestMinute(lengthInSeconds) * 60;
}

export function getUserFriendlyStockType(contentType: string) {
  switch (contentType) {
    case contentTypes.TEMPLATE:
      return userFriendlySearchTypes.TEMPLATE;
    case contentTypes.FOOTAGE:
      return userFriendlySearchTypes.FOOTAGE;
    case contentTypes.MOTION_BG:
      return userFriendlySearchTypes.MOTION_BG;
    case contentTypes.ILLUSTRATION:
      return userFriendlySearchTypes.ILLUSTRATION;
    case contentTypes.VECTOR:
      return userFriendlySearchTypes.VECTOR;
    case contentTypes.PHOTO:
      return userFriendlySearchTypes.PHOTO;
    case contentTypes.SNAPSHOT:
      return userFriendlySearchTypes.SNAPSHOT;
    case contentTypes.VR_360:
      return userFriendlySearchTypes.VR_360;
    case contentTypes.MUSIC:
      return userFriendlySearchTypes.MUSIC;
    case contentTypes.SOUND_EFFECT:
      return userFriendlySearchTypes.SOUND_EFFECT;
    default:
      return '';
  }
}

export function getStockLicenseType($stockItem) {
  switch ($stockItem) {
    case 1:
      return ENTERPRISE_LICENSE_NAME;
    case 2:
      return BUSINESS_LICENSE_NAME;
    default:
      return INDIVIDUAL_LICENSE_NAME;
  }
}

export const convertStockItemContextToOriginArea = (
  context?: StockItemContext
): OriginArea | null => {
  switch (context) {
    case StockItemContext.FOLDER:
      return OriginArea.FOLDERS;
    case StockItemContext.SEARCH:
      return OriginArea.SEARCH;
    case StockItemContext.STORYBOARD:
      return OriginArea.STORYBOARD;
    default:
      return null;
  }
};

export function getArtistFullName(artist: StockItemArtistInterface) {
  return artist.lastName
    ? `${artist.firstName} ${artist.lastName}`
    : artist.firstName;
}

export function titleCase(str = '') {
  const splitStr = str.toLowerCase().split(' ');
  const titleCased = splitStr.map(
    (word) => word.charAt(0).toUpperCase() + word.substring(1)
  );

  // Directly return the joined string
  return titleCased.join(' ');
}

// https://www.30secondsofcode.org/js/s/slugify
export const slugify = (str = '') =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // remove non-word, non-space characters before `-`
    .replace(/[\s+-]+/g, '-') // internal spaces to `-`
    .replace(/^-+|-+$/g, ''); // remove (leading) `-`

export function isMouseOverElement(mouseEvent, element) {
  if (!mouseEvent || !element) {
    return false;
  }

  const { top, right, bottom, left } = element.getBoundingClientRect();
  const { x: mouseX, y: mouseY } = mouseEvent;

  return mouseX > left && mouseX < right && mouseY > top && mouseY < bottom;
}

/**
 * Convert a "short UTC string" to a utc iso string.
 * Safari fails to parse the short strings with the Date constructor.
 *
 * @param shortUTCString {string} A string such as "2016-04-16 15:08:38".
 * @return {string} A string such as "2016-04-16T15:08:38Z".
 */
export function shortUtcToIsoString(shortUTCString) {
  if (!shortUTCString) return shortUTCString;

  // Put a "T" character where the space is and ensure it ends in exactly one "Z" character.
  return `${shortUTCString}Z`.replace(/ /, 'T').replace(/Z+$/, 'Z');
}

export const getHtmlSafeName = (rawName) => {
  return rawName.replace(/\W/g, '');
};

export const isCollectionItemNew = (collectionItem) => {
  if (!collectionItem || !collectionItem.dateAdded) {
    return false;
  }
  const dateAdded = collectionItem.dateAdded;
  const utcDateAdded = new Date(dateAdded);
  const millisecondsInASecond = 1000;
  const secondsInADay = 86400;
  const dateToNumber = (date = new Date()): number => Date.parse(String(date));

  const getDaysSinceDateAdded = Math.floor(
    Math.abs(dateToNumber(utcDateAdded) - dateToNumber()) /
      millisecondsInASecond /
      secondsInADay
  );

  return getDaysSinceDateAdded < 100;
};

export const startRecommendedMusicDrawerTimer = () => {
  window.recommendedMusicDrawerTimer = Telemetry.timer(
    'search.recommended_music.timer'
  );
};

export const stopRecommendedMusicDrawerTimer = () => {
  window.recommendedMusicDrawerTimer?.();
  window.recommendedMusicDrawerTimer = null;
};

export const someKeysDownHandler =
  (callback: () => void, keys = ['Enter']): KeyboardEventHandler =>
  (evt) => {
    if (keys.includes(evt.key)) {
      callback();
    }
  };

//Lodash replacement functions
export const isEmpty = (n) => {
  return !(!!n
    ? typeof n === 'object'
      ? Array.isArray(n)
        ? !!n.length
        : !!Object.keys(n).length
      : true
    : false);
};

export const startCase = (str) => {
  if (str === null) {
    return null;
  }
  const lower = String(str).toLowerCase();
  return lower.replace(/(^| )(\w)/g, function (x) {
    return x.toUpperCase();
  });
};

export const isEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => isEqual(x[key], y[key]))
    : x === y;
};

export const get = (obj, path, defValue = undefined) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

export const times = (n, func = (i) => i) =>
  Array.from({ length: n }).map((_, i) => func(i));
